// Home.js
import React, { useState } from 'react';
import './App.css';
import axios from 'axios';

function Home() {
    // 保存用户输入的电子邮件
    const [email, setEmail] = useState('');

    // 保存给用户的反馈消息
    const [message, setMessage] = useState('');

    // 处理提交表单的逻辑
    const handleSubmit = async (event) => {
        event.preventDefault(); // 阻止表单的默认提交行为
        try {
            // 向服务器发送包含用户电子邮件的POST请求
            const response = await axios.post('https://aetherianchronicles.com:4000/api/submit', { email });
            if (response.data.success) {
                setMessage('Subscription successful!'); // 成功消息
                setEmail(''); // 清空输入框
            } else {
                setMessage('Subscription failed, please try again.'); // 失败消息
            }
        } catch (error) {
            // 处理请求失败的错误
            setMessage(`An error occurred: ${error.message || 'Unknown error'}`);
        }
    };

    return (
        <div className="App">
            {/* 页头部分 */}
            <div className="header">
                {/* 标题图片部分 */}
                <img src={`${process.env.PUBLIC_URL}/images/Logo.png`} alt="Logo" className="title"/>
                {/* 链接按钮 */}
                <div className="social-buttons">
                    {/* 社交媒体链接 */}
                    <a href="https://www.reddit.com/user/AetherianChronicles/">
                        <img src={`${process.env.PUBLIC_URL}/images/1.png`} alt="Reddit"/>
                    </a>
                    <a href="https://www.facebook.com/people/Aetherian-Chronicles/61562158711764/">
                        <img src={`${process.env.PUBLIC_URL}/images/3.png`} alt="Facebook"/>
                    </a>
                    <a href="https://x.com/KFishPlatforms">
                        <img src={`${process.env.PUBLIC_URL}/images/4.png`} alt="Twitter"/>
                    </a>
                    <a href="https://www.youtube.com/@KFishPlatformsAC">
                        <img src={`${process.env.PUBLIC_URL}/images/5.png`} alt="YouTube"/>
                    </a>
                    <a href="https://www.tiktok.com/@kfishplatforms">
                        <img src={`${process.env.PUBLIC_URL}/images/6.png`} alt="TikTok"/>
                    </a>
                </div>
            </div>

            {/* 标题图片部分 */}
            <img src={`${process.env.PUBLIC_URL}/images/title.png`} alt="Title" className="content-logo"/>
            <img src={`${process.env.PUBLIC_URL}/images/start_title.png`} alt="Start" className="content-start-text"/>

            {/* 内容部分 */}
            <div className="input-content">
                <img src={`${process.env.PUBLIC_URL}/images/enter_email.png`} alt="Enter Email"
                     className="content-text"/>
                <form  className="input-table"  onSubmit={handleSubmit}>
                    <div className="div1">
                        {/* 电子邮件输入框 */}
                        <input
                            type="text"
                            placeholder="Enter Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="div2">
                        {/* 提交按钮 */}
                        <button type="submit"></button>
                    </div>
                </form>
                {/* 反馈消息显示 */}
                {message && <p className="message">{message}</p>}
            </div>

            <div className="foot-content">
                <div className="row buttons-row">
                    <div className="row">
                        <img src={`${process.env.PUBLIC_URL}/images/discordtext.png`} alt="Ready" className="img-ready"/>
                    </div>
                    <div className="column" align={'center'}>
                        <a href="https://discord.com/invite/5p9s6YTZ">
                            <img src={`${process.env.PUBLIC_URL}/images/Discord.png`} alt="Discord" className="discord-button"/>
                        </a>
                        <a href="https://store.steampowered.com/app/3060390/Aetherian_Chronicles/">
                            <img src={`${process.env.PUBLIC_URL}/images/Steam2.png`} alt="Steam" className="steam-button"/>
                        </a>
                    </div>
                </div>
            </div>


            {/* 其他图片部分 */}
            <img src={`${process.env.PUBLIC_URL}/images/Sub.png`} alt="Subscribe" className="img-sub"/>
            <img src={`${process.env.PUBLIC_URL}/images/char.png`} alt="Character" className="img-char"/>
        </div>
    );
}

export default Home;
